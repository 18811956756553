<template>
  <b-card-code title="Créer un nouveau employé">
    <b-card-text>
      <span>Veuillez saisir les coordonnées de l'employé </span>
    </b-card-text>

    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Nom"
              label-for="nom"
            >
              <validation-provider
                #default="{ errors }"
                name="nom"
                rules="required"
              >
                <b-form-input
                  v-model="employee.first_name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Nom"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Prénom"
              label-for="Prénom"
            >
              <validation-provider
                #default="{ errors }"
                name="Prénom"
                rules="required"
              >
                <b-form-input
                  v-model="employee.last_name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Prénom"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Téléphone"
              label-for="Téléphone"
            >
              <validation-provider
                #default="{ errors }"
                name="Téléphone"
                rules="integer|length:8"
              >
                <b-form-input
                  v-model="employee.phone"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Téléphone "
                  maxlength="8"
                  type="number"
                  :formatter="formatPhone"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Email"
              label-for="Email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required"
              >
                <b-form-input
                  v-model="employee.email"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Email"
                  type="email"
                  required
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="cin"
              label-for="cin"
            >
              <validation-provider
                #default="{ errors }"
                name="cin"
              >
                <b-form-input
                  v-model="employee.cin"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Cin"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Adresse"
              label-for="address"
            >
              <validation-provider
                #default="{ errors }"
                name="address"
              >
                <b-form-input
                  v-model="employee.address"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Adresse"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Mot de passe"
              label-for="a-password"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="Password"
                rules="required|min:8"
              >
                <b-form-input
                  id="a-password"
                  v-model="password"
                  :state="errors.length > 0 ? false : null"
                  type="password"
                  placeholder="Password"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- confirm password -->
          <b-col cols="6">
            <b-form-group
              label="Confirmer mot de passe"
              label-for="password"
            >
              <validation-provider
                #default="{ errors }"
                name="Confirm Password"
                rules="required|confirmed:Password"
              >
                <b-form-input
                  id="ac-password"
                  v-model="employee.password"
                  :state="errors.length > 0 ? false : null"
                  type="password"
                  placeholder="Confirm Password"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Fonction"
              label-for="Fonction"
            >
              <validation-provider
                #default="{ errors }"
                name="Fonction"
              >
                <b-form-input
                  v-model="employee.function"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Fonction"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="6"
          >
            <b-form-group
              label="Role"
              label-for="Role"
            >
              <validation-provider
                #default="{ errors }"
                name="Role"
                rules="required"
              >
                <v-select
                  v-model="employee.role"
                  :clearable="false"
                  placeholder="Role"
                  label="name"
                  :options="roles"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click.prevent="addEmployee"
            >
              Ajouter
            </b-button>
            <b-button
              variant="outline-secondary"
              type="reset"
            >
              Réinitialiser
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText /*  */,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    /* BFormTextarea, */
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
  },
  data() {
    return {
      password: '',
      roles: [],
      employee: {
        first_name: '',
        last_name: '',
        username: '',
        email: '',
        password: '',
        role: '',
        function: '',
        address: '',
        cin: '',
        phone: '',
      },
      required,
    }
  },

  created() {
    this.getRoles()
  },
  methods: {
    async getRoles() {
      const response = await axios.get('/api/authentication/groups/')
      this.roles = response.data
    },
    async addEmployee() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            await axios
              .post(
                '/api/authentication/register/',
                {
                  first_name: this.employee.first_name,
                  last_name: this.employee.last_name,
                  username: `${this.employee.first_name} ${this.employee.last_name}`,
                  email: this.employee.email,
                  password: this.employee.password,
                  groups: this.employee.role.id,
                  phone: this.employee.phone,
                  cin: this.employee.cin,
                  address: this.employee.address,
                  function: this.employee.function,
                },
              )
            this.$router.push('/employees')
            setTimeout(() => {
              this.showToast('success', 'top-center', 'Employée ajouté avec succés')
            }, 1000)
          } catch (err) {
            setTimeout(() => {
              this.showToast('danger', 'top-center', err.toString())
            }, 1000)
          }
        }
      })
    },

    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
    formatPhone(e) {
      return String(e).substring(0, 8)
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',{attrs:{"title":"Créer un nouveau employé"}},[_c('b-card-text',[_c('span',[_vm._v("Veuillez saisir les coordonnées de l'employé ")])]),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nom","label-for":"nom"}},[_c('validation-provider',{attrs:{"name":"nom","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Nom"},model:{value:(_vm.employee.first_name),callback:function ($$v) {_vm.$set(_vm.employee, "first_name", $$v)},expression:"employee.first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Prénom","label-for":"Prénom"}},[_c('validation-provider',{attrs:{"name":"Prénom","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Prénom"},model:{value:(_vm.employee.last_name),callback:function ($$v) {_vm.$set(_vm.employee, "last_name", $$v)},expression:"employee.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Téléphone","label-for":"Téléphone"}},[_c('validation-provider',{attrs:{"name":"Téléphone","rules":"integer|length:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Téléphone ","maxlength":"8","type":"number","formatter":_vm.formatPhone},model:{value:(_vm.employee.phone),callback:function ($$v) {_vm.$set(_vm.employee, "phone", $$v)},expression:"employee.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"Email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Email","type":"email","required":""},model:{value:(_vm.employee.email),callback:function ($$v) {_vm.$set(_vm.employee, "email", $$v)},expression:"employee.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"cin","label-for":"cin"}},[_c('validation-provider',{attrs:{"name":"cin"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Cin"},model:{value:(_vm.employee.cin),callback:function ($$v) {_vm.$set(_vm.employee, "cin", $$v)},expression:"employee.cin"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Adresse","label-for":"address"}},[_c('validation-provider',{attrs:{"name":"address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Adresse"},model:{value:(_vm.employee.address),callback:function ($$v) {_vm.$set(_vm.employee, "address", $$v)},expression:"employee.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Mot de passe","label-for":"a-password"}},[_c('validation-provider',{attrs:{"name":"Password","vid":"Password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"a-password","state":errors.length > 0 ? false : null,"type":"password","placeholder":"Password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Confirmer mot de passe","label-for":"password"}},[_c('validation-provider',{attrs:{"name":"Confirm Password","rules":"required|confirmed:Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"ac-password","state":errors.length > 0 ? false : null,"type":"password","placeholder":"Confirm Password"},model:{value:(_vm.employee.password),callback:function ($$v) {_vm.$set(_vm.employee, "password", $$v)},expression:"employee.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Fonction","label-for":"Fonction"}},[_c('validation-provider',{attrs:{"name":"Fonction"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Fonction"},model:{value:(_vm.employee.function),callback:function ($$v) {_vm.$set(_vm.employee, "function", $$v)},expression:"employee.function"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Role","label-for":"Role"}},[_c('validation-provider',{attrs:{"name":"Role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"clearable":false,"placeholder":"Role","label":"name","options":_vm.roles},model:{value:(_vm.employee.role),callback:function ($$v) {_vm.$set(_vm.employee, "role", $$v)},expression:"employee.role"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.addEmployee.apply(null, arguments)}}},[_vm._v(" Ajouter ")]),_c('b-button',{attrs:{"variant":"outline-secondary","type":"reset"}},[_vm._v(" Réinitialiser ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }